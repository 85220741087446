import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";

const ContattiPage = ({ data }) => {
  const cf = data.site.siteMetadata.companyInfo;

  return (
    <Layout title="Contatti">
      <section className="section">
        <div className="container">
          <h1 className="title is-2">Contatti</h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h3 className="title is-5">Email</h3>
              <p>
                <a href={`mailto:${cf.email}`}>{cf.email}</a>
              </p>
            </div>

            <div className="column">
              <h3 className="title is-5">Telefono e Fax</h3>
              <p>Tel: {cf.telephone}</p>
              <p>Fax: {cf.fax}</p>
            </div>

            <div className="column">
              <h3 className="title is-5">Ufficio</h3>
              <p>
                {cf.address.street}, {cf.address.locality} ({cf.address.region}){" "}
                {cf.address.postalCode}, {cf.address.country}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ContactInfoQuery {
    site {
      siteMetadata {
        companyInfo {
          email
          telephone
          fax
          address {
            street
            locality
            region
            postalCode
            country
          }
          opens
          closes
        }
      }
    }
  }
`;

ContattiPage.propTypes = {
  data: PropTypes.shape({ site: PropTypes.object }).isRequired
};

export default ContattiPage;
